import { gql } from '@apollo/client';

export const TRIPS = gql`
  query Trips(
    $order: String
    $orderOptions: OrderOptions
    $limit: Int
    $divisions: [String]
    $destinations: [String]
    $regions: [String]
    $ids: [String]
    $notIds: [String]
    $notDestinations: [String]
    $ages: [String]
    $durations: [String]
    $activity_tags: [String]
    $partnerIds: [Int]
    $offset: Int
    $currency: String
    $codes: [String]
    $degrees: [String]
    $userCountry: String
  ) {
    trips(
      order: $order
      orderOptions: $orderOptions
      limit: $limit
      divisions: $divisions
      destinations: $destinations
      regions: $regions
      ids: $ids
      notIds: $notIds
      notDestinations: $notDestinations
      ages: $ages
      durations: $durations
      activity_tags: $activity_tags
      partnerIds: $partnerIds
      offset: $offset
      currency: $currency
      codes: $codes
      degrees: $degrees
      userCountry: $userCountry
    ) {
      items {
        id
        division
        destination
        region
        code
        bannerImg
        bannerVideo
        bannerVideoCover
        tripName
        partner {
          id
          name
        }
        age {
          min
          max
        }
        duration {
          min
          max
        }
        prices {
          AUD
          CAD
          USD
          GBP
          EUR
          NZD
        }
        reviews {
          count
          rating
        }
        durationType
        tag
        activity_tags {
          id
          name
        }
        url
        promotions {
          code
          type
          expires
          percent
          init
          amount {
            AUD
            CAD
            USD
            GBP
            EUR
            NZD
          }
        }
        isDegreeRequired
        details {
          title
          content
          type
        }
      }
      length
      offset
    }
  }
`;

export const RECENT_DISCOVERIES_TRIP = gql`
  query RecentDiscoveriesTrip($ids: [String]) {
    trips(ids: $ids) {
      items {
        id
        division
        destination
        code
        bannerImg
        tripName
        prices {
          AUD
          CAD
          USD
          GBP
          EUR
          NZD
        }
        deposit {
          AUD
          CAD
          USD
          GBP
          EUR
          NZD
        }
        reviews {
          count
          rating
        }
        url
        promotions {
          code
          type
          expires
          percent
          init
          amount {
            AUD
            CAD
            USD
            GBP
            EUR
            NZD
          }
        }
      }
    }
  }
`;

export const TRIPS_FOR_XML = gql`
  query TripsForXml {
    trips {
      items {
        id
        division
        destination
        code
        bannerImg
        bannerVideo
        bannerVideoCover
        tripName
        url
        prices {
          AUD
          CAD
          USD
          GBP
          EUR
          NZD
        }
        promotions {
          type
          expires
          init
          amount {
            AUD
            GBP
            USD
            EUR
            CAD
            NZD
          }
        }
      }
    }
  }
`;

export const TRIP_BY_ID = gql`
  query TripById($id: Int!) {
    trip(id: $id) {
      id
      division
      divisionId
      destination
      code
      tripName
      globalExclusive
      earliestAvailability
      bannerImg
      bannerVideo
      bannerVideoCover
      orientation
      gallery {
        path
        description
      }
      ca
      tripConfigs {
        cutOff
        isFlexible
        availabilityUnit
        defaultDurationId
        isDegreeRequired
      }
      partner {
        id
        name
      }
      destinationDetails {
        name
        region
      }
      itinerary {
        title
        content
        location {
          lat
          lng
          zoom
          city
          region
          country
        }
      }
      details {
        title
        content
        type
      }
      inclusions {
        title
        description
        icon
        conditions {
          countries
        }
        tags
      }
      qualifiers {
        minAge
        maxAge
        minAgeBuffer
        maxAgeBuffer
        validCountries
        excludeCriminal
      }
      minMaxAge {
        min
        max
      }
      minMaxPrice {
        min {
          AUD
          CAD
          USD
          GBP
          EUR
          NZD
        }
        max {
          AUD
          CAD
          USD
          GBP
          EUR
          NZD
        }
      }
      minMaxDuration {
        min
        max
      }
      deposit {
        AUD
        CAD
        USD
        GBP
        EUR
        NZD
      }
      durationType
      visas {
        name
        requirements
      }
      map {
        lat
        lng
        zoom
      }
      reviews {
        count
        rating
      }
      promotions {
        code
        type
        expires
        percent
        init
        amount {
          AUD
          CAD
          USD
          GBP
          EUR
          NZD
        }
      }
    }
  }
`;

export const GET_TRIP_PRICE = gql`
  query TripPrice($id: Int!) {
    trip(id: $id) {
      id
      earliestAvailability
      minMaxPrice {
        min {
          AUD
          CAD
          USD
          GBP
          EUR
          NZD
        }
        max {
          AUD
          CAD
          USD
          GBP
          EUR
          NZD
        }
      }
      deposit {
        AUD
        CAD
        USD
        GBP
        EUR
        NZD
      }
      promotions {
        code
        type
        expires
        percent
        init
        amount {
          AUD
          CAD
          USD
          GBP
          EUR
          NZD
        }
      }
    }
  }
`;

export const TRIP_VIEWS = gql`
  query TripViews($url: String, $from: Int, $to: Int) {
    pages {
      views(url: $url, from: $from, to: $to) {
        visitsCount
      }
    }
  }
`;

export const TRIP_AVAILABILITY_DATA = gql`
  query TripAvailabilityData($tripId: Int) {
    TripAvailabilityData(tripId: $tripId) {
      year
      type
      months {
        comment
        tags
        status
        dates {
          start
          end
          duration
          price {
            AUD
            CAD
            USD
            GBP
            EUR
            NZD
          }
          durationId
        }
        availabilities {
          comment
          tags
          status
          dates {
            start
            end
            duration
            price {
              AUD
              CAD
              USD
              GBP
              EUR
              NZD
            }
            durationId
            availabilityId
          }
        }
      }
    }
  }
`;

export const BOOK_URL_QUERY = gql`
  query BookUrlQuery {
    bookUrl {
      url
    }
  }
`;

export const TOUR_OPERATORS_AND_DESTINATIONS_SEARCH_PANEL = gql`
  query TourOperators {
    trips(divisions: ["Tour"]) {
      items {
        partner {
          id
          name
        }
        destination
        region
      }
    }
  }
`;

export const TRIP_HAS_ROOMS = gql`
  query TripHasRooms($id: Int!) {
    tripHasRooms(id: $id)
  }
`;

export const Product_Urgency_Banner_Collection = gql`
  query ProductUrgencyBanner(
    $division: String
    $destination: String
    $productId: String
  ) {
    byDivison: productUrgencyBannerCollection(
      where: { type: "Division", filter: $division }
    ) {
      items {
        emoji
        description
        tag
      }
    }

    byDestination: productUrgencyBannerCollection(
      where: { type: "Destination", filter: $destination }
    ) {
      items {
        emoji
        description
        tag
      }
    }

    byProductID: productUrgencyBannerCollection(
      where: { type: "Product ID", filter: $productId }
    ) {
      items {
        emoji
        description
        tag
      }
    }
  }
`;

export const TRIPS_WITH_INCLUESION = gql`
  query Trips(
    $order: String
    $limit: Int
    $divisions: [String]
    $destinations: [String]
    $regions: [String]
    $ids: [String]
    $notIds: [String]
    $notDestinations: [String]
    $ages: [String]
    $durations: [String]
    $activity_tags: [String]
    $partnerIds: [Int]
    $offset: Int
    $currency: String
    $codes: [String]
  ) {
    trips(
      order: $order
      limit: $limit
      divisions: $divisions
      destinations: $destinations
      regions: $regions
      ids: $ids
      notIds: $notIds
      notDestinations: $notDestinations
      ages: $ages
      durations: $durations
      activity_tags: $activity_tags
      partnerIds: $partnerIds
      offset: $offset
      currency: $currency
      codes: $codes
    ) {
      items {
        id
        division
        destination
        region
        code
        bannerImg
        bannerVideo
        bannerVideoCover
        tripName
        partner {
          id
          name
        }
        age {
          min
          max
        }
        duration {
          min
          max
        }
        prices {
          AUD
          CAD
          USD
          GBP
          EUR
          NZD
        }
        reviews {
          count
          rating
        }
        durationType
        tag
        activity_tags {
          id
          name
        }
        url
        promotions {
          code
          type
          expires
          percent
          init
          amount {
            AUD
            CAD
            USD
            GBP
            EUR
            NZD
          }
        }
        isDegreeRequired
      }
      length
      offset
      inclusions {
        title
        description
        icon
        conditions {
          countries
        }
        tags
        tripId
      }
    }
  }
`;
