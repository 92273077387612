import { useEffect, useContext, useRef, FC, useState } from 'react';
import styles from './promotion-banner.module.css';
import merge from 'deepmerge';
import { GImage } from '../g-image';
import { useLocation } from '@/Lib/hooks/useLocation';
import { BannerStyles } from '@/Lib/types/trip';
import { useWindowDimensions } from '@/Lib/hooks/useWindowDimensions';
import { HeaderVisiblityContext } from 'layouts/MainLayout';
import { PromotionBannerTimer } from './promotion-banner-timer';
import { RudderAnalytics } from '../ruddarstak/rudderanalytics';
import { useAtomValue } from 'jotai';
import { promotionsAtom } from '@/State/global/global.store';
import { useCustomRouter } from '@/Lib/hooks/useCustomRouter';
import { useCurrency } from '@/Lib/hooks/useCurrency';
import { usePromotionText } from './usePromotionText';

const DEFAULT_STYLES = {
  background: '#FFE6CF',
  closeButton: {
    background: '#0E7285',
    color: '#fff',
  },
  countdown: {
    color: '#007285',
  },
  imageLeft: '/icons/banner-left-graphic.svg',
  imageRight: '/icons/banner-right-graphic.svg',
  width: {
    timer: '665px',
    noTimer: '780px',
  },
};

const PromotionBanner: FC = () => {
  const { width } = useWindowDimensions();
  const { location } = useLocation();
  const { currency } = useCurrency();
  const router = useCustomRouter();
  const bannerRef = useRef<HTMLDivElement>(null);
  const { bannerPromotion, closeBanner, setHeight, isActive } =
    useAtomValue(promotionsAtom);
  const headerContext = useContext(HeaderVisiblityContext);

  const [isVisible, setIsVisible] = useState(false);

  let mergedStyles: BannerStyles = DEFAULT_STYLES;
  if (bannerPromotion) {
    if (bannerPromotion.banner_styles) {
      let styles =
        typeof bannerPromotion.banner_styles === 'string'
          ? JSON.parse(bannerPromotion.banner_styles)
          : bannerPromotion.banner_styles;

      mergedStyles = merge(DEFAULT_STYLES, styles);
    }
  }

  const { text, isLoading } = usePromotionText({ data: bannerPromotion });

  useEffect(() => {
    setTimeout(() => {
      if (isActive) {
        setIsVisible(true);

        setTimeout(() => {
          if (bannerPromotion && setHeight && bannerRef && bannerRef.current) {
            RudderAnalytics.promotionViewed({
              promo: bannerPromotion.code,
              type: bannerPromotion.type,
              promoAmount:
                (bannerPromotion.amount && bannerPromotion.amount[currency]) ||
                0,
              revenue:
                (bannerPromotion.amount && bannerPromotion.amount[currency]) ||
                0,
              url: router.pathname.split('?')[0],
              currency: currency,
              country: location.country.name,
            });
          }
        }, 300);
      }
    }, 4000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerRef, bannerPromotion, setHeight, isActive]);

  useEffect(() => {
    if (isActive && isVisible && bannerRef && bannerRef.current && setHeight) {
      setHeight(bannerRef.current.getBoundingClientRect().height);
    }
  }, [
    width,
    bannerRef,
    bannerPromotion,
    setHeight,
    isActive,
    isVisible,
    headerContext?.isHeaderVisible,
  ]);

  function onCloseHandler() {
    if (closeBanner) closeBanner();
  }

  const onClickBanner = () => {
    RudderAnalytics.promotionClicked({
      promo: bannerPromotion?.code || '',
      type: bannerPromotion?.type || '',
      promoAmount: bannerPromotion?.amount[currency] || 0,
      revenue: bannerPromotion?.amount[currency] || 0,
      url: router.pathname.split('?')[0],
      currency: currency,
      country: location.country.name,
    });

    if (mergedStyles?.link) {
      router.push(mergedStyles.link);
    }
  };

  // Note:
  // There lies a trick here, the outer most div exists merely so that
  // we are able to animate the banner with max-height but also be able
  // to ensure that the second div (immediate child of the outmost) has
  // a min-height. This also affects the fact that the inner div
  // facilitates the height calculation (and its racing issues)
  return (
    <div
      className={
        'transition-maxHeight duration-500 overflow-hidden' +
        (isActive && isVisible ? ' max-h-30' : ' max-h-0')
      }
    >
      <div
        className={
          styles.promotionBanner +
          ' z-30 relative overflow-hidden px-9 md:px-0 min-h-3.75rem'
        }
        style={{
          background: mergedStyles.background,
          cursor: mergedStyles.link ? 'pointer' : '',
          height: mergedStyles.height,
        }}
        ref={bannerRef}
        onClick={onClickBanner}
        suppressHydrationWarning
      >
        {mergedStyles.imageLeft ? (
          <div className="hidden lg:block h-full absolute lg:right-85% xl:right-3/4 w-500 top-0">
            <GImage
              classes="object-right"
              height="100%"
              width="100%"
              path={`website${mergedStyles.imageLeft}`}
              alt="travel doodle art"
            />
            s
          </div>
        ) : null}

        {bannerPromotion ? (
          <div className="flex flex-col sm:flex-row items-center pt-0.156rem sm:pt-0">
            {!isLoading && text ? (
              <div
                className={styles.bannerText}
                style={{
                  maxWidth: bannerPromotion.show_banner_countdown
                    ? mergedStyles.width?.timer
                    : mergedStyles.width?.noTimer,
                }}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              ></div>
            ) : null}

            <PromotionBannerTimer
              promotion={bannerPromotion}
              mergedStyles={mergedStyles}
            />
          </div>
        ) : null}

        {mergedStyles.imageRight ? (
          <div className="hidden lg:block h-full absolute lg:left-85% xl:left-3/4 w-500 top-0">
            <GImage
              classes="object-left"
              height="100%"
              width="100%"
              path={`website${mergedStyles.imageRight}`}
              alt="travel doodle art"
            />
          </div>
        ) : null}

        <div className={styles.bannerActions}>
          <button
            className={styles.bannerCloseAction}
            onClick={onCloseHandler}
            style={{
              background: mergedStyles.closeButton?.background,
              color: mergedStyles.closeButton?.color,
            }}
          >
            x
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromotionBanner;
