import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function getFirstOrFifteenth() {
  const date = dayjs();
  const utcDate = date.utc();

  const dayOfMonth = utcDate.date();

  if (dayOfMonth < 15) {
    return utcDate.startOf('month').unix();
  } else {
    return utcDate.date(15).startOf('day').unix();
  }
}

export function getAssetVersion() {
  return getFirstOrFifteenth();
}
